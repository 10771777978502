
.main{
  width: 100%;
  height: 100%;
}
.navbar-brand,
.navbar-nav .nav-link {
  color: black;
  font-size: 17px;
  margin-left: 20px;
  font-weight: bold;
}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: darkorange;

}

.navbar {
  background-image: url(./component/img/back.jpg);
  background-size: cover;
  background-repeat: no-repeat;

}

.head {
  width: 100%;
  height: 55px;
  display: flex;

}

.head2 {
  width: 33%;
  height: 100%;
  margin-left: 02px;

}

.head2 p {
  font-size: 17px;
  margin-left: 30px;
}

.head2 a {
  margin-left: 10px;

}

.slider img {
  width: 100%;
  height: 420px;

}

.section {
  width: 90%;
  margin-top: 10px;
  margin-left: 05%;
  height: 550px;
  display: flex;
}

.section2 {
  width: 50%;
  height: 100%;

}

.section2 h1 {
  margin-top: 50px;
  font-size: 35px;
  margin-left: 30px;
}

.section2 span {
  margin-top: 40px;
  font-size: 35px;
}

.section2 p {
  margin-top: 20px;
  font-size: 17px;
  color: black;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  max-width: 90%;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.section2 video {
  width: 100%;
  margin-top: 35px;
  border-radius: 16px;
}

.services{
  display: flex;
  margin-top: 20px;
}

.serve2{
  margin-left: 40px;
  width: 18rem;
 
}
.serve2 img {
  width: 100%;
  height: 180px;
}

.texts{
  width: 100%;
  height: 170px;
}

.backs{
  background-color: lightyellow;
}

.maps{
  width: 100%;
  height: 400px;
  margin-top: 50px;
}



.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
  margin-top: 50px;
}

.gallery img {
  width: 100%;
  height: 200px; /* Adjust the height as needed */
  object-fit: cover; /* Ensure the image covers the specified dimensions */
  transition: transform 0.3s ease; /* Add transition effect for smoother transformation */
}

.gallery img:hover {
  transform: scale(1.1); /* Scale up the image on hover */
}
